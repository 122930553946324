import type { EstateType } from '~/types/EstateType'

export default function getEstateApiBaseUrl(estateType: EstateType) {
  let baseUrl = '/office-properties'

  if (estateType === 'warehouse') baseUrl = '/industrial-properties'
  if (estateType === 'retail') baseUrl = '/retail-properties'

  return baseUrl
}
